.sharedpath-login {
    overflow: auto;
    height: 100%;

    .pad {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .tm32 {
        margin-top: 32px;
    }

    .paper {
        margin-top: 24px;
        display: 'flex';
        flex-direction: 'column';
        align-items: 'center';
    }
}

