.sidebar {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    display: none;
    height: 75%;
    left: 55px;
    overflow: auto;
    padding: 30px 20px;
    position: absolute;
    top: calc(50% + 32px);
    transform: translateY(-50%);
    width: 30%;
    min-width: 280px;
    max-width: 500px;
    z-index: 1000;

    .close-sidebar {
        position: absolute;
        top: 20px;
        right: 10px;
        cursor: pointer;
        width: 30px;
        height: 30px;

        &:before {
            content: '';
            width: 24px;
            height: 2px;
            background: #000;
            position: absolute;
            transform: rotate(45deg);
            right: 2px;
            top: 13px;
        }

        &:after {
            content: '';
            width: 24px;
            height: 2px;
            background: #000;
            position: absolute;
            transform: rotate(-45deg);
            right: 2px;
            top: 13px;
        }
    }

    .sidebar-title {
        font-size: 26px;
        margin-bottom: 25px;
    }

    .community-info {
        border-bottom: 1px solid #dfdfdf;
        padding: 15px 0;

        &:last-child {
            border-bottom: 0;
        }

        span {
            display: block;
        }

        .name {
            font-weight: 500;
            margin-bottom: 4px;
            font-size: 18px;
        }
    }
}
